import { useToast } from '@react-aria/toast';
import { mergeProps, useObjectRef } from '@react-aria/utils';
import { type QueuedToast, type ToastState } from '@react-stately/toast';
import { type HTMLAttributes, forwardRef } from 'react';
import { Button } from 'react-aria-components';

import { CloseIcon } from '../../icons';
import { cn } from '../../utils';

import { CenterBaseline } from '../content';
import type { ToastOptions, ToastValue, ToastVariant } from './ToastQueue';

type ToastPrimitiveProps = HTMLAttributes<HTMLDivElement> & {
  state: ToastState<ToastValue>;
  toast: QueuedToast<ToastValue>;
};

/**
 * @private Internal component used to render a toast notification.
 */
export const ToastPrimitive = forwardRef<HTMLDivElement, ToastPrimitiveProps>(
  function ToastPrimitive(props, forwardedRef) {
    const { state, toast, ...domProps } = props;
    const { actionElement, content, title, variant } = toast.content;

    const domRef = useObjectRef(forwardedRef);
    const {
      closeButtonProps,
      contentProps,
      descriptionProps,
      titleProps,
      toastProps,
    } = useToast(props, state, domRef);

    return (
      <div
        {...mergeProps(toastProps, domProps)}
        className={cn(
          'group',
          'bg-canvas border-borderInteractiveSubtle relative rounded-md border shadow-xl outline-none',
          // animations
          'data-[animation=entering]:animate-in data-[animation=entering]:fade-in-0 data-[animation=entering]:slide-in-from-bottom-1/2 data-[animation=entering]:zoom-in-95',
          'data-[animation=exiting]:animate-out data-[animation=exiting]:fade-out-0',
          domProps.className
        )}
      >
        <div className="flex transition-opacity duration-300 group-data-[collapsed]:opacity-0">
          <div className="border-subtle my-2 grid flex-1 gap-y-3 border-r px-4 py-2">
            <div className="title-xs-medium flex items-baseline justify-between gap-x-2">
              <span {...titleProps}>{title}</span>

              {isBadgeVariant(variant) && (
                <CenterBaseline>
                  <VariantBadge variant={variant} {...descriptionProps} />
                </CenterBaseline>
              )}
            </div>

            <div
              {...contentProps}
              className="text-secondary body-xs-normal flex items-center gap-x-2"
            >
              <div className="flex-1">{content}</div>

              {actionElement}
            </div>
          </div>

          <Button
            {...closeButtonProps}
            className={cn(
              'text-secondary flex w-12 items-center justify-center rounded-sm outline-none outline-offset-[0] transition-colors',
              'hover:text-default',
              'focus-visible:outline-accent'
            )}
          >
            <CloseIcon className="size-4" />
          </Button>
        </div>
      </div>
    );
  }
);

// Styled components
// -----------------------------------------------------------------------------

const BADGE_TEXT: Record<NonNeutralVariant, string> = {
  onchain: 'On-chain',
  error: 'Error',
};

type VariantBadgeProps = {
  variant: NonNeutralVariant;
} & HTMLAttributes<HTMLDivElement>;

function VariantBadge(props: VariantBadgeProps) {
  const { variant, ...domProps } = props;

  return (
    <div
      {...domProps}
      className={cn(
        'whitespace-nowrap rounded border border-[currentColor] px-1',
        {
          'text-accent': variant === 'onchain',
          'text-critical': variant === 'error',
        },
        domProps.className
      )}
    >
      {BADGE_TEXT[variant]}
    </div>
  );
}

type NonNeutralVariant = Exclude<ToastVariant, 'neutral'>;

function isBadgeVariant(variant: ToastVariant): variant is NonNeutralVariant {
  return variant !== 'neutral';
}

/**
 * @private Spoof component to get Storybook’s `argTypes` working. Match
 * prop types with the options for `ToastQueue.add()`.
 *
 * NOTE: Must be exported, doesn’t work inline from the stories file.
 */
export function StorybookArgTypesHack(props: ToastOptions) {
  return <div>{props.content}</div>;
}
