// TODO: this is a temporary custom loading state type, to be used where necessary before a large refactor to React Query
export type LoadingState<TLoaded, TLoading = null> =
  | {
      isLoading: true;
      value: TLoading;
    }
  | {
      isLoading: false;
      value: TLoaded;
    };

export const WORKER_VERSION_HEADER = 'x-worker-version';
