import { type ReactNode, forwardRef } from 'react';
import {
  type SearchFieldProps as RACSearchFieldProps,
  Button as RACButton,
  Provider,
  SearchField as RACSearchField,
} from 'react-aria-components';

import { CloseIcon, SearchIcon } from '../../icons';
import { type StyleProps, cn } from '../../utils';
import {
  Adornment,
  FieldContext,
  FieldLabel,
  HelpText,
  Input,
  InputGroup,
} from '../field';

export type SearchFieldProps = {
  /**
   * The label of the field. For "visually hidden" labels, use the `aria-label`
   * attribute.
   */
  label?: string;
  /** The label extension is displayed after the label, in a less prominent font. */
  labelExtension?: string;
  /**
   * Hint text provides extra context or instruction about what a user should
   * input in the field.
   */
  hint?: string;
  /**
   * Error messages inform the user when the input does not meet validation
   * criteria. Use with `validationBehavior` of "aria".
   */
  errorMessage?: string;
  /**
   * Avoid placeholders, which are inaccessible and disappear during input.
   * Prefer `hint` text to offer essential information.
   */
  placeholder?: string;
  /**
   * The size of the search field's input and related elements.
   * @default medium
   */
  size?: 'small' | 'medium' | 'large';
} & Omit<
  RACSearchFieldProps,
  'children' | 'isInvalid' | 'className' | 'style'
> &
  StyleProps;

// ^ RAC omissions:
// - children, className, and style: render props not supported
// - isInvalid: derived from `errorMessage` prop

/**
 * A text field that allows users to specify a word or a phrase to find relevant
 * content without navigation.
 */
export const SearchField = forwardRef<HTMLInputElement, SearchFieldProps>(
  function SearchField(props, forwardedRef) {
    const {
      className,
      errorMessage,
      hint,
      label,
      labelExtension,
      size = 'medium',
      ...otherProps
    } = props;
    const isClearable = !props.isDisabled && !props.isReadOnly;

    return (
      <RACSearchField
        className={cn('flex w-full flex-col items-start gap-2', className)}
        isInvalid={!!errorMessage || undefined}
        {...otherProps}
      >
        {(renderProps) => (
          <Provider values={[[FieldContext, { size, ...renderProps }]]}>
            {label || labelExtension ? (
              <FieldLabel extension={labelExtension}>{label}</FieldLabel>
            ) : null}

            <InputGroup role="presentation" className="group">
              <Adornment placement="start">
                <SearchIcon />
              </Adornment>

              <Input
                ref={forwardedRef}
                className="[&::-webkit-search-cancel-button]:appearance-none [&::-webkit-search-decoration]:appearance-none"
              />

              {isClearable && (
                <RACButton
                  className={cn(
                    'text-secondary flex aspect-square h-full cursor-default select-none items-center justify-center opacity-0 transition-all',
                    'hover:text-default',
                    {
                      'group-focus-within:opacity-100 group-hover:opacity-100':
                        !renderProps.isEmpty,
                    }
                  )}
                >
                  <CloseIcon
                    className={cn({
                      'size-5': size === 'medium' || size === 'small',
                      'size-6': size === 'large',
                    })}
                  />
                </RACButton>
              )}
            </InputGroup>

            <HelpText description={hint}>{errorMessage}</HelpText>
          </Provider>
        )}
      </RACSearchField>
    );
  }
);
