import { type ForwardedRef, forwardRef } from 'react';
import {
  type ModalOverlayProps,
  ModalOverlay,
  Modal,
} from 'react-aria-components';

import { cn, StyleProps } from '../../utils';

export type TrayProps = {
  /**
   * When true, the tray will fill all available vertical space. Use this option
   * when the content is likely to change height due to user interaction.
   * @default false
   */
  fixedHeight?: boolean;
} & Omit<ModalOverlayProps, 'className' | 'style'> &
  StyleProps;

const MIN_TOUCH_AREA = 44;

/**
 * @private Trays are containers that display transient content such as menus,
 * options, additional actions, and more. They should only be used for mobile
 * experiences, where popovers are not appropriate.
 */
export const Tray = forwardRef(function Tray(
  props: TrayProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const { children, className, fixedHeight, ...otherProps } = props;
  return (
    <ModalOverlay
      isDismissable
      className={cn(
        'z-dialog bg-blanket fixed left-0 top-0 w-screen backdrop-blur-sm',
        'flex flex-col items-stretch justify-end',
        'entering:animate-in entering:fade-in entering:duration-300 entering:ease-out',
        'exiting:animate-out exiting:fade-out exiting:duration-200 exiting:ease-in'
      )}
      style={{
        height: 'var(--visual-viewport-height)',
      }}
    >
      <Modal
        ref={ref}
        className={cn(
          'bg-canvas overflow-hidden rounded-t-md shadow-lg outline-none',
          'entering:animate-in entering:slide-in-from-bottom entering:duration-300 entering:ease-out',
          'exiting:animate-out exiting:slide-out-to-bottom exiting:duration-200 exiting:ease-in',
          className
        )}
        style={{
          height: fixedHeight
            ? `calc(var(--visual-viewport-height) - ${MIN_TOUCH_AREA}px)`
            : undefined,
          // always define max-height so it can be inherited
          maxHeight: `calc(var(--visual-viewport-height) - ${MIN_TOUCH_AREA}px)`,
        }}
        {...otherProps}
      >
        {children}
      </Modal>
    </ModalOverlay>
  );
});
