import { useObjectRef } from '@react-aria/utils';
import type { AriaLabelingProps, DOMProps } from '@react-types/shared';
import { type SlotProps, OTPInput, REGEXP_ONLY_DIGITS } from 'input-otp';
import {
  type ChangeEvent,
  type ForwardedRef,
  forwardRef,
  useContext,
} from 'react';
import { type AriaTextFieldProps, useTextField } from 'react-aria';
import {
  FieldErrorContext,
  Provider,
  TextContext,
} from 'react-aria-components';

import { type StyleProps, cn } from '../../utils';
import { FieldContext, FieldLabel, HelpText } from '../field';

// Override base type to change the default, for consistency with other components.
type RACValidation = {
  /**
   * Whether to use native HTML form validation to prevent form submission
   * when the value is missing or invalid, or mark the field as required
   * or invalid via ARIA.
   * @default 'native'
   */
  validationBehavior?: 'native' | 'aria';
};

export type OtpFieldProps = {
  /**
   * The label of the field. For "visually hidden" labels, use the `aria-label`
   * attribute.
   */
  label?: string;
  /**
   * The number of digits the user must input.
   * @default 6
   */
  length?: number;
  /**
   * Hint text is displayed below the label to give extra context or instruction
   * about what a user should input in the field.
   */
  hint?: string;
  /** The callback that is invoked when the value changes. */
  onChange?: (value: string) => void;
  /** The callback that is invoked when the `length` is reached. */
  onComplete?: (value: string) => void;
  /**
   * The size of the field.
   * @default 'medium'
   */
  size?: 'small' | 'medium' | 'large';
  /** The value of the input. */
  value?: string;
} & Pick<
  AriaTextFieldProps,
  | 'autoFocus'
  | 'errorMessage'
  | 'isDisabled'
  | 'isRequired'
  | 'onBlur'
  | 'onFocus'
  | 'validate'
> &
  RACValidation &
  AriaLabelingProps &
  DOMProps &
  StyleProps;

/**
 * A one-time password field allows the input of a string of characters, that
 * authenticates a user for a single transaction or session.
 */
export const OtpField = forwardRef(function OtpField(
  props: OtpFieldProps,
  forwardedRef: ForwardedRef<HTMLInputElement>
) {
  const {
    className,
    errorMessage,
    hint,
    isDisabled,
    isRequired,
    label,
    length = 6,
    onComplete,
    size = 'medium',
    style,
    value,
  } = props;

  const inputRef = useObjectRef(forwardedRef);
  const {
    descriptionProps,
    errorMessageProps,
    inputProps,
    labelProps,
    ...validation
  } = useTextField(
    {
      isInvalid: !!errorMessage || undefined,
      validationBehavior: 'native',
      ...props,
    },
    inputRef
  );

  return (
    <div
      className={cn('flex w-full flex-col items-start gap-2', className)}
      style={style}
    >
      <Provider
        values={[
          [
            FieldContext,
            {
              isDisabled,
              isInvalid: validation.isInvalid,
              isRequired,
              size,
            },
          ],
          [
            TextContext,
            {
              slots: {
                description: descriptionProps,
                errorMessage: errorMessageProps,
              },
            },
          ],
          [FieldErrorContext, validation],
        ]}
      >
        {label ? <FieldLabel {...labelProps}>{label}</FieldLabel> : null}

        <OTPInput
          {...inputProps}
          ref={inputRef}
          autoComplete="one-time-code"
          // Explicitly `undefined` children resolves type issue where RAC
          // input props spread above conflicts with "input-otp" props.
          children={undefined}
          containerClassName="flex justify-start gap-2"
          disabled={isDisabled}
          inputMode="numeric"
          maxLength={length}
          // react-aria expects a traditional change handler but "input-otp"
          // only calls with the text value. We need to manually create an
          // event-like object so validation works as expected for consumers.
          onChange={(value) => {
            inputProps.onChange?.({
              target: { value },
            } as ChangeEvent<HTMLInputElement>);
          }}
          onComplete={onComplete}
          pasteTransformer={removeNonDigitChars}
          pattern={REGEXP_ONLY_DIGITS}
          value={value}
          render={({ isHovering, slots }) => (
            <>
              {slots.map((slot, idx) => (
                <Slot key={idx} isHovered={isHovering} {...slot} />
              ))}
            </>
          )}
        />

        <HelpText description={hint}>{errorMessage}</HelpText>
      </Provider>
    </div>
  );
});

function Slot(props: SlotProps & { isHovered: boolean }) {
  const fieldCtx = useContext(FieldContext);

  return (
    <div
      data-rac=""
      data-disabled={fieldCtx.isDisabled || undefined}
      data-focused={props.isActive || undefined}
      data-hovered={props.isHovered || undefined}
      data-invalid={fieldCtx.isInvalid || undefined}
      className={cn(
        'relative flex aspect-[5/6] items-center justify-center',
        'bg-shark4 text-default rounded border border-[transparent]',
        'outline-none outline outline-0 outline-offset-[-1px] transition-colors',
        'hover:bg-shark5 hover:border-interactiveHover',
        'focus:outline-accent focus:outline-2',
        'invalid:border-interactiveCritical invalid:hover:border-interactiveCritical',
        'disabled:bg-shark4/50 disabled:text-disabled disabled:cursor-default',
        {
          'body-base-normal h-8': fieldCtx.size === 'small',
          'body-lg-normal h-10': fieldCtx.size === 'medium',
          'body-xl-normal h-12': fieldCtx.size === 'large',
        }
      )}
    >
      <span>{props.char}</span>
      {props.hasFakeCaret && <FakeCaret />}
    </div>
  );
}

function FakeCaret() {
  return (
    <div className="animate-caret-blink pointer-events-none absolute inset-0 flex items-center justify-center">
      <div className="bg-accentEmphasis h-[1em] w-px" />
    </div>
  );
}

function removeNonDigitChars(value: string) {
  return value.replace(/[^\d]/g, '');
}
