'use client';

import { useEffect, useRef, useState } from 'react';

import { breakpoints } from '../components/breakpoints/breakpoints';

/** Tracks the previous value of a given value. */
export function usePrevious<T>(value: T) {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

/** Runs the provided callback **once**, when the component mounts. */
export function useMountEffect(callback: () => void) {
  useEffect(() => {
    callback();
    // empty deps ensures the effect runs only once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

/** Whether the screen width is less than the "md" breakpoint. */
export function useIsSmallScreen() {
  if (typeof window === 'undefined') {
    return false;
  }

  return window.screen.width < breakpoints.md;
}

/**
 * Tracks the current browser tab/window visibility.
 *
 * @returns `true` when e.g. the user switched tabs or minimized the window.
 */
export const useIsDocumentHidden = () => {
  const [isDocumentHidden, setIsDocumentHidden] = useState(
    typeof document !== 'undefined' ? document.hidden : false
  );

  useEffect(() => {
    if (typeof document === 'undefined') {
      return;
    }

    const callback = () => {
      setIsDocumentHidden(document.hidden);
    };
    document.addEventListener('visibilitychange', callback);
    return () => document.removeEventListener('visibilitychange', callback);
  }, []);

  return isDocumentHidden;
};
