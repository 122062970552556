import { createIcon } from '../createIcon';

export const SendIcon = createIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M20.885 5.365c.523-1.404-.846-2.773-2.25-2.25L4.14 8.51c-1.422.529-1.542 2.494-.195 3.193l5.427 2.816a.25.25 0 0 1 .107.107l2.816 5.427c.699 1.347 2.664 1.227 3.194-.195l5.395-14.494Zm-2.904-.406L4.664 9.916a.25.25 0 0 0-.027.457l5.221 2.708 8.123-8.122Zm-7.062 9.183 2.708 5.221c.1.193.381.176.457-.027L19.04 6.018l-8.122 8.123Z"
  />,
  'SendIcon'
);
