import { type ForwardedRef, forwardRef } from 'react';
import {
  type PopoverProps as RACPopoverProps,
  type DialogProps as RACDialogProps,
  type DialogTriggerProps as RACDialogTriggerProps,
  DialogTrigger as RACDialogTrigger,
  Dialog as RACDialog,
  Popover as RACPopover,
  Provider,
  ModalContext,
} from 'react-aria-components';

import { type StyleProps, cn, useIsSmallScreen } from '../../utils';
import { ContentContext, FooterContext, HeadingContext } from '../content';
import { Dialog } from '../dialog';
import { Tray } from './Tray';

// TODO: size (width) prop?
export type PopoverProps = {
  /**
   * The type of alternative dialog to render on small screens.
   * @default 'modal'
   */
  mobileType?: 'fullscreen' | 'modal' | 'tray';
} & Pick<RACDialogProps, 'children'> &
  Omit<
    PopoverPrimitiveProps,
    | 'children'
    | 'className'
    | 'isEntering'
    | 'isExiting'
    | 'isKeyboardDismissDisabled'
    | 'isNonModal'
    | 'scrollRef'
    | 'shouldCloseOnInteractOutside'
    | 'shouldFlip'
    | 'shouldUpdatePosition'
    | 'style'
    | 'trigger'
  > &
  StyleProps;

/**
 * A popover is a transient overlay element positioned relative to a trigger.
 */
export const Popover = forwardRef(function Popover(
  props: PopoverProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const { children, className, mobileType, style, ...otherProps } = props;
  const isSmallScreen = useIsSmallScreen();

  const popoverAndTrayContent = (
    <Provider
      values={[
        [
          HeadingContext,
          {
            className: 'title-lg-semibold border-b border-subtle py-3 mx-4',
            slot: 'title',
          },
        ],
        [ContentContext, { className: 'flex-1 overflow-y-auto p-4' }],
        [FooterContext, { className: 'border-t border-subtle mx-4 py-4' }],
      ]}
    >
      <RACDialog
        ref={ref}
        className={cn(
          'flex max-h-[inherit] max-w-[inherit] flex-col outline-none',
          className
        )}
        style={style}
      >
        {children}
      </RACDialog>
    </Provider>
  );

  if (isSmallScreen) {
    let dialog = (
      <Dialog
        {...props}
        isDismissable
        ref={ref}
        size={mobileType === 'fullscreen' ? 'fullscreen' : undefined}
      />
    );

    if (mobileType === 'tray') {
      dialog = <Tray {...otherProps}>{popoverAndTrayContent}</Tray>;
    }

    return (
      <ModalContext.Provider
        value={{
          isOpen: props.isOpen,
          onOpenChange: props.onOpenChange,
        }}
      >
        {dialog}
      </ModalContext.Provider>
    );
  }

  return (
    <PopoverPrimitive {...otherProps}>{popoverAndTrayContent}</PopoverPrimitive>
  );
});

// Primitive
// -----------------------------------------------------------------------------

const CONTAINER_PADDING = 12;

export type PopoverPrimitiveProps = Omit<
  RACPopoverProps,
  | 'arrowBoundaryOffset'
  | 'containerPadding'
  | 'crossOffset'
  | 'offset'
  | 'UNSTABLE_portalContainer'
>;

/**
 * @private Used internally by e.g. `Combobox` and `Select` components,
 * where the content is not necessarily a dialog.
 */
export const PopoverPrimitive = forwardRef(function PopoverPrimitive(
  props: PopoverPrimitiveProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const { className, style, ...otherProps } = props;

  return (
    <RACPopover
      {...otherProps}
      containerPadding={CONTAINER_PADDING}
      ref={ref}
      className={cn(
        'bg-canvas border-subtle rounded-lg border shadow-lg',
        // common animations
        'entering:animate-in entering:fade-in entering:ease-out',
        'exiting:animate-out exiting:fade-out',
        // placement animations
        'data-[placement=bottom]:entering:slide-in-from-top-2',
        'data-[placement=top]:entering:slide-in-from-bottom-2',
        'data-[placement=left]:entering:slide-in-from-right-2',
        'data-[placement=right]:entering:slide-in-from-left-2',
        className
      )}
      style={{
        ...style,
        maxWidth: `calc(100vw - ${CONTAINER_PADDING * 2}px)`,
      }}
    />
  );
});

// Trigger
// -----------------------------------------------------------------------------

export type PopoverTriggerProps = RACDialogTriggerProps;

/**
 * A controller for popover state, bound to an associated trigger; linking the
 * `Popover` open state with the trigger’s press state.
 */
export function PopoverTrigger(props: PopoverTriggerProps) {
  return <RACDialogTrigger {...props} />;
}
