import { type ReactNode } from 'react';

import { type StyleProps, cn } from '../../utils';

export type CenterBaselineProps = {
  /** The child element to center. */
  children: ReactNode;
} & StyleProps;

/**
 * Center an element with the baseline of a sibling text element. Used in cases
 * where the related text content may wrap e.g. checkbox + label text.
 */
export function CenterBaseline(props: CenterBaselineProps) {
  const { className, ...otherProps } = props;

  return (
    <div
      className={cn(
        'flex items-center',
        'before:invisible before:content-["⁠"]',
        className
      )}
      {...otherProps}
    />
  );
}
