import { cva } from 'class-variance-authority';
import { forwardRef } from 'react';
import {
  type TextFieldProps as RACTextFieldProps,
  Provider,
  TextArea as RACTextArea,
  TextField as RACTextField,
} from 'react-aria-components';

import { type StyleProps, cn } from '../../utils';
import { FieldContext, FieldLabel, HelpText, InputGroup } from '../field';

export type TextAreaProps = {
  /** The label of the field. For "visually hidden" labels, use the `aria-label` attribute. */
  label?: string;
  /** The label extension is displayed after the label, in a less prominent font. */
  labelExtension?: string;
  /**
   * Hint text is displayed below the label to give extra context or instruction
   * about what a user should input in the field.
   */
  hint?: string;
  /**
   * Error messages inform the user when the input does not meet validation
   * criteria. Use with `validationBehavior` of "aria".
   */
  errorMessage?: string;
  /**
   * Avoid placeholders, which are inaccessible and disappear during input.
   * Prefer `hint` text to offer essential information.
   */
  placeholder?: string;
  /**
   * The initial (and minimum) number of rows to display. Users may resize
   * the text area to fit more content.
   * @default 3
   */
  rows?: number;
  /**
   * The size of the text field.
   * @default medium
   */
  size?: 'small' | 'medium' | 'large';
} & Omit<
  RACTextFieldProps,
  | 'children'
  | 'className'
  | 'inputMode'
  | 'isInvalid'
  | 'pattern'
  | 'style'
  | 'type'
> &
  StyleProps;

// ^ RAC omissions:
// - children, className, and style: render props not supported
// - isInvalid: derived from `errorMessage` prop
// - inputMode, pattern, type: not appropriate for text areas

/**
 * Text areas are multiline text inputs, useful for cases where users have a
 * sizable amount of text to enter.
 */
export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  function TextArea(props, forwardedRef) {
    const {
      className,
      errorMessage,
      hint,
      label,
      labelExtension,
      rows = 3,
      size = 'medium',
      ...otherProps
    } = props;

    return (
      <RACTextField
        className={cn('flex w-full flex-col items-start gap-2', className)}
        isInvalid={!!errorMessage || undefined}
        {...otherProps}
      >
        {(renderProps) => (
          <Provider values={[[FieldContext, { size, ...renderProps }]]}>
            {label || labelExtension ? (
              <FieldLabel extension={labelExtension}>{label}</FieldLabel>
            ) : null}

            <InputGroup className="h-auto">
              <RACTextArea
                ref={forwardedRef}
                rows={rows}
                className={cn('py-[1ex]', inputVariants({ size }))}
                style={{
                  // prevent resize below the initial number of rows
                  minHeight: `calc(${rows} * 1lh + 2ex)`,
                }}
              />
            </InputGroup>

            <HelpText description={hint}>{errorMessage}</HelpText>
          </Provider>
        )}
      </RACTextField>
    );
  }
);

const inputVariants = cva(
  'w-full bg-none outline-none placeholder:text-subtle text-default caret-interactiveActive disabled:text-disabled',
  {
    variants: {
      // fix for mobile safari zoom behaviour:
      // - "touch" prefix targets `hover: none + pointer: coarse` by media query
      // - increase font to 16px (min allowed)
      size: {
        small: 'min-h-8 body-sm-normal px-3 touch:body-base-normal',
        medium: 'min-h-10 body-sm-normal px-3 touch:body-base-normal',
        large: 'min-h-12 body-base-normal px-4',
      },
    },
  }
);
