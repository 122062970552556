import { type ForwardedRef, type ReactNode, forwardRef } from 'react';
import { type SlotProps, Link as RacLink } from 'react-aria-components';

import type { CommonLinkProps, LinkDOMProps } from './types';

export type LinkProps = {
  /** The content of the element. */
  children: ReactNode;
} & LinkDOMProps &
  CommonLinkProps &
  SlotProps;

export const Link = forwardRef(function Link(
  props: LinkProps,
  ref: ForwardedRef<HTMLAnchorElement>
) {
  return <RacLink ref={ref} {...props} />;
});
