import { createContext, forwardRef } from 'react';
import {
  type CheckboxGroupProps as RACCheckboxGroupProps,
  composeRenderProps,
  Provider,
  CheckboxGroup as RACCheckboxGroup,
} from 'react-aria-components';

import { type StyleProps, cn } from '../../utils';
import { FieldContext, FieldLabel, HelpText } from '../field';

export const CheckboxGroupContext = createContext<boolean>(false);

export type CheckboxGroupProps = {
  /** The label of the field. For "visually hidden" labels, use the `aria-label` attribute. */
  label?: string;
  /** The label extension is displayed after the label, in a less prominent font. */
  labelExtension?: string;
  /**
   * Hint text is displayed below the label to give extra context or instruction
   * about what a user should choose for the value.
   */
  hint?: string;
  /**
   * The size of the radios and other field elements.
   * @default medium
   */
  size?: 'small' | 'medium' | 'large';
} & Omit<
  RACCheckboxGroupProps,
  'orientation' | 'className' | 'style' | 'isInvalid' | 'validationBehavior'
> &
  StyleProps;

// ^ RAC omissions:
// - children, className, and style: render props not supported
// - isInvalid, validationBehavior: we don't (yet) support realtime validation

/**
 * A checkbox group allows the user to select one or more items from a list of
 * options.
 */
export const CheckboxGroup = forwardRef<HTMLDivElement, CheckboxGroupProps>(
  function CheckboxGroup(props, forwardedRef) {
    const {
      className,
      hint,
      label,
      labelExtension,
      size = 'medium',
      ...otherProps
    } = props;

    return (
      <RACCheckboxGroup
        className={cn('flex w-full flex-col items-start gap-2', className)}
        ref={forwardedRef}
        {...otherProps}
      >
        {composeRenderProps(props.children, (children, renderProps) => (
          <Provider
            values={[
              [FieldContext, { size, ...renderProps }],
              [CheckboxGroupContext, true],
            ]}
          >
            {label || labelExtension ? (
              <FieldLabel extension={labelExtension}>{label}</FieldLabel>
            ) : null}

            <div className="grid gap-2">{children}</div>

            <HelpText description={hint} />
          </Provider>
        ))}
      </RACCheckboxGroup>
    );
  }
);
