import { ForwardedRef, forwardRef } from 'react';

import { FrameInspectIcon } from '../../icons';
import { cn, truncateAddress } from '../../utils';

import { type TextLinkProps, TextLink } from './TextLink';

export type BlockExplorerLinkProps = {
  /**
   * The address to display.
   */
  children: string;
  /**
   * The block explorer URL.
   */
  href: string;
} & Pick<TextLinkProps, 'className' | 'style' | 'prominence' | 'onPress'>;

/**
 * Opinionated wrapper around `TextLink` that includes an indicator icon and
 * truncates the provided `children` address.
 */
export const BlockExplorerLink = forwardRef(function BlockExplorerLink(
  props: BlockExplorerLinkProps,
  ref: ForwardedRef<HTMLAnchorElement>
) {
  const { children, ...otherProps } = props;

  return (
    <TextLink
      {...otherProps}
      ref={ref}
      target="_blank"
      className={cn('whitespace-nowrap', props.className)}
      title={children}
    >
      <FrameInspectIcon />
      {truncateAddress(children, 4)}
    </TextLink>
  );
});
