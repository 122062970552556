import { createContext } from 'react';
import { Provider } from 'react-aria-components';

export type SwitchGroupProps = {
  /** The child switch components. */
  children: React.ReactNode;
  /** Whether each switch in the group is disabled. */
  isDisabled?: boolean;
  /**
   * The size of each switch in the group.
   * @default medium
   */
  size?: 'small' | 'medium';
};

type SwitchGroupContextType = Omit<SwitchGroupProps, 'children'>;

export const SwitchGroupContext = createContext<SwitchGroupContextType | null>(
  null
);

/**
 * Used for related settings or preferences, the `SwitchGroup` primarily
 * influences layout. `Switch` components inside the group are displayed with
 * the label first, and the control right-aligned (in LTR languages) to the
 * container.
 *
 * For convenience, it also offers an opportunity to provide "size" and
 * disabled state to each control contained within.
 */
export function SwitchGroup(props: SwitchGroupProps) {
  const { children, isDisabled, size = 'medium' } = props;

  return (
    <Provider values={[[SwitchGroupContext, { isDisabled, size }]]}>
      {children}
    </Provider>
  );
}
