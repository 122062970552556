import { cva } from 'class-variance-authority';
import {
  type ModalOverlayProps,
  composeRenderProps,
  ModalOverlay,
  Modal as RACModal,
} from 'react-aria-components';

import { type LiteralVariantProps, cn } from '../../utils';

const modalVariants = cva('bg-canvas', {
  variants: {
    size: {
      small:
        'max-w-[calc(100%_-_2rem)] border border-subtle rounded-xl shadow-xl',
      medium:
        'max-w-[calc(100%_-_2rem)] border border-subtle rounded-xl shadow-xl',
      large:
        'max-w-[calc(100%_-_2rem)] border border-subtle rounded-xl shadow-xl',
      fullscreen: 'h-full',
    },
  },
  defaultVariants: {
    size: 'medium',
  },
});

type ModalVariant = LiteralVariantProps<typeof modalVariants>;
export type ModalProps = {
  /**
   * The size of the modal.
   * @default 'medium'
   */
  size?: ModalVariant['size'];
} & ModalOverlayProps;

/** @private Consumed internally by the `Dialog` component. */
export function Modal(props: ModalProps) {
  const { isDismissable, isKeyboardDismissDisabled, size } = props;
  return (
    <ModalOverlay
      isDismissable={isDismissable}
      isKeyboardDismissDisabled={isKeyboardDismissDisabled}
      className={cn(
        'z-dialog bg-blanket fixed inset-y-0 flex w-screen items-center justify-center backdrop-blur-sm',
        'entering:animate-in entering:fade-in entering:duration-300 entering:ease-out',
        'exiting:animate-out exiting:fade-out exiting:duration-200 exiting:ease-in'
      )}
      style={{
        maxHeight: 'var(--visual-viewport-height)',
      }}
    >
      <RACModal
        className={cn(
          {
            'entering:animate-in entering:slide-in-from-bottom-4 entering:duration-300 entering:ease-out':
              size !== 'fullscreen',
            'exiting:animate-out exiting:slide-out-to-bottom-2 exiting:duration-200 exiting:ease-in':
              size !== 'fullscreen',
          },
          modalVariants({ size })
        )}
      >
        {composeRenderProps(props.children, (children, modal) => {
          if (modal.isEntering || modal.isExiting || modal.state.isOpen) {
            return children;
          }

          return null;
        })}
      </RACModal>
    </ModalOverlay>
  );
}
