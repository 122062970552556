type Options = {
  /**
   * Include the `rac` identifier in the returned props.
   * @default false
   */
  includeRacIdentifier?: boolean;
  /**
   * Omit props with values of `false` and `""`. Nullish values are always
   * omitted, and values of `0` are valid in this situation.
   * @default true
   */
  omitFalsyValues?: boolean;
  /**
   * Removes the `is*` prefix from keys, if present.
   * @default true
   */
  trimBooleanKeys?: boolean;
};

/**
 * Transforms an object of properties into HTML data attributes. Basically just
 * converts keys to kebab-case, with some optimisations and extra functionality.
 */
export function toDataAttributes<T extends object>(
  props: T,
  options: Options = {}
) {
  const {
    includeRacIdentifier = false,
    omitFalsyValues = true,
    trimBooleanKeys = true,
  } = options;

  const dataAttributes: Record<string, T[keyof T] | undefined> = {};

  for (const key in props) {
    let prop: string = key;
    const value = props[key];

    // Always bail if the value is nullish; it'll never make it to the DOM node
    // so there's no point doing any more work.
    // A value of `0` isn't really falsy in this case so it's not included in
    // the optional check.
    if (
      value == null ||
      (omitFalsyValues && (value === false || value === ''))
    ) {
      continue;
    }

    // Lowercase the first letter of the remaining key so it isn't affected by
    // the kebab-case conversion later.
    if (
      trimBooleanKeys &&
      key.startsWith('is') &&
      key[2] === key[2].toUpperCase()
    ) {
      prop = prop.charAt(2).toLowerCase() + prop.slice(3);
    }

    prop = prop.replace(/[A-Z]/g, (char) => `-${char.toLowerCase()}`);

    dataAttributes[`data-${prop}`] = value;
  }

  if (includeRacIdentifier) {
    // @ts-expect-error — intentionally adding rac identifier
    dataAttributes['data-rac'] = '';
  }

  return dataAttributes;
}
