import { type ReactNode, forwardRef } from 'react';
import {
  type TextFieldProps as RACTextFieldProps,
  Provider,
  TextField as RACTextField,
} from 'react-aria-components';

import { type StyleProps, cn } from '../../utils';

import {
  Adornment,
  FieldContext,
  FieldLabel,
  HelpText,
  Input,
  InputGroup,
} from '../field';

export type TextFieldProps = {
  /**
   * The label of the field. For "visually hidden" labels, use the `aria-label`
   * attribute.
   */
  label?: string;
  /** The label extension is displayed after the label, in a less prominent font. */
  labelExtension?: string;
  /**
   * Hint text provides extra context or instruction about what a user should
   * input in the field.
   */
  hint?: string;
  /**
   * Error messages inform the user when the input does not meet validation
   * criteria. Use with `validationBehavior` of "aria".
   */
  errorMessage?: string;
  /**
   * Avoid placeholders, which are inaccessible and disappear during input.
   * Prefer `hint` text to offer essential information.
   */
  placeholder?: string;
  /**
   * The size of the text field's input element.
   * @default medium
   */
  size?: 'small' | 'medium' | 'large';
  /** Element to display before the input. */
  startElement?: ReactNode;
  /** Element to display after the input. */
  endElement?: ReactNode;
} & Omit<RACTextFieldProps, 'children' | 'isInvalid' | 'className' | 'style'> &
  StyleProps;

// ^ RAC omissions:
// - children, className, and style: render props not supported
// - isInvalid: derived from `errorMessage` prop

/**
 * Text fields allow users to input text with a keyboard. Use when the expected
 * input is a single line of text.
 */
export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  function TextField(props, forwardedRef) {
    const {
      className,
      endElement,
      errorMessage,
      hint,
      label,
      labelExtension,
      size = 'medium',
      startElement,
      ...otherProps
    } = props;

    return (
      <RACTextField
        className={cn('flex w-full flex-col items-start gap-2', className)}
        isInvalid={!!errorMessage || undefined}
        {...otherProps}
      >
        {(renderProps) => (
          <Provider values={[[FieldContext, { size, ...renderProps }]]}>
            {label || labelExtension ? (
              <FieldLabel extension={labelExtension}>{label}</FieldLabel>
            ) : null}

            <InputGroup role="presentation">
              {startElement && (
                <Adornment placement="start">{startElement}</Adornment>
              )}

              <Input ref={forwardedRef} />

              {endElement && (
                <Adornment placement="end">{endElement}</Adornment>
              )}
            </InputGroup>

            <HelpText description={hint}>{errorMessage}</HelpText>
          </Provider>
        )}
      </RACTextField>
    );
  }
);
