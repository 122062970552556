import { createIcon } from '../createIcon';

export const PlaceholderTokenIcon = createIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M22.5004 9.75V14.25C22.5004 17.5637 17.7994 20.25 12.0004 20.25C6.20138 20.25 1.50037 17.5637 1.50037 14.25V9.75C1.50037 6.43629 6.20138 3.75 12.0004 3.75C17.7994 3.75 22.5004 6.43629 22.5004 9.75ZM5.81869 12.7542C9.50407 15.4345 14.4968 15.4345 18.1822 12.7542C18.6843 12.389 18.3451 11.5964 17.7343 11.7075L14.1471 12.3597C12.7276 12.6178 11.2733 12.6178 9.85384 12.3597L6.26656 11.7075C5.65576 11.5964 5.31661 12.389 5.81869 12.7542Z"
  />,
  'PlaceholderTokenIcon'
);
