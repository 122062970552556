import { createIcon } from '../createIcon';

export const MoveIcon = createIcon(
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 8.25a3.75 3.75 0 0 1 3.75-3.75h7.5a3.75 3.75 0 0 1 3.75 3.75v7.5a3.75 3.75 0 0 1-3.75 3.75h-7.5a3.75 3.75 0 0 1-3.75-3.75v-7.5ZM12.25 6A2.25 2.25 0 0 0 10 8.25v7.5A2.25 2.25 0 0 0 12.25 18h7.5A2.25 2.25 0 0 0 22 15.75v-7.5A2.25 2.25 0 0 0 19.75 6h-7.5Z"
    />
    <path d="M4.25 6.5A2.25 2.25 0 0 0 2 8.75v.5a.75.75 0 0 1-1.5 0v-.5A3.75 3.75 0 0 1 4.25 5h.5a.75.75 0 0 1 0 1.5h-.5ZM2 14.75a.75.75 0 0 0-1.5 0v.5A3.75 3.75 0 0 0 4.25 19h.5a.75.75 0 0 0 0-1.5h-.5A2.25 2.25 0 0 1 2 15.25v-.5Z" />
  </>,
  'MoveIcon'
);
