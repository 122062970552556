import { createIcon } from '../createIcon';

export const SwidgeIcon = createIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M7.00245 11C9.21267 11 11.0044 9.20914 11.0044 7C11.0044 4.79086 9.21267 3 7.00245 3C4.79223 3 3.00049 4.79086 3.00049 7C3.00049 8.1801 3.51178 9.24084 4.32502 9.97299L2.48886 13.0052C0.63873 16.0604 4.4995 19.3487 7.22399 17.0381L17.7568 8.10561C19.1839 6.89533 21.2062 8.61776 20.2371 10.2181L18.3961 13.2494C17.962 13.0881 17.4923 13 17.002 13C14.7917 13 13 14.7909 13 17C13 19.2091 14.7917 21 17.002 21C19.2122 21 21.0039 19.2091 21.0039 17C21.0039 15.8199 20.4926 14.7592 19.6794 14.027L21.521 10.9948C23.3711 7.93959 19.5103 4.6513 16.7858 6.96185L6.25304 15.8944C4.82594 17.1047 2.80362 15.3822 3.77274 13.7819L5.60835 10.7506C6.04246 10.9119 6.51216 11 7.00245 11ZM7.00245 9.5C8.38384 9.5 9.50368 8.38071 9.50368 7C9.50368 5.61929 8.38384 4.5 7.00245 4.5C5.62106 4.5 4.50122 5.61929 4.50122 7C4.50122 8.38071 5.62106 9.5 7.00245 9.5ZM19.5032 17C19.5032 18.3807 18.3834 19.5 17.002 19.5C15.6206 19.5 14.5007 18.3807 14.5007 17C14.5007 15.6193 15.6206 14.5 17.002 14.5C18.3834 14.5 19.5032 15.6193 19.5032 17Z"
  />,
  'SwidgeIcon'
);
