import { Maybe } from './types';

/**
 * Returns the aria-current value for a given pathname and href.
 * @param pathname - The current pathname.
 * @param href - The item's href.
 */
export function ariaCurrentPage(pathname: string | null, href: string) {
  if (!pathname) return 'false';
  if (pathname === href) return 'page';
  if (pathname.startsWith(href) && href !== '/') return 'true';
  return 'false';
}

/**
 * Thin wrapper around `join()` that filters out “falsy” values and returns
 * `undefined` if the result is empty.
 */
export function joinIds(ids?: Maybe<string>[]) {
  // cheap length check before filtering
  if (!ids || ids.length === 0) {
    return undefined;
  }

  const filteredIds = ids.filter(Boolean);
  if (filteredIds.length === 0) {
    return undefined;
  }

  return filteredIds.join(' ');
}
