import { createContext, forwardRef } from 'react';
import {
  type ContextValue,
  Provider,
  useContextProps,
} from 'react-aria-components';

import { IconContext } from '../../icons';
import { cn, isReactText } from '../../utils';
import { Text, TextContext } from '../content';
import { Link } from '../link';
import {
  buttonVariants,
  iconSizeClasses,
  textSizeClasses,
} from './buttonVariants';
import type { LinkButtonProps } from './types';

export const LinkButtonContext =
  createContext<ContextValue<LinkButtonProps, HTMLAnchorElement>>(null);

/**
 * Shares all the same `Button` cosmetics but with link semantics, allowing
 * users to navigate to another page.
 */
export const LinkButton = forwardRef<HTMLAnchorElement, LinkButtonProps>(
  function LinkButton(props, ref) {
    [props, ref] = useContextProps(props, ref, LinkButtonContext);
    const {
      children,
      className,
      prominence,
      size = 'medium',
      ...otherProps
    } = props;

    return (
      <Provider
        values={[
          [IconContext, { className: iconSizeClasses[size] }],
          [TextContext, { className: textSizeClasses[size] }],
        ]}
      >
        <Link
          {...otherProps}
          className={cn(buttonVariants({ prominence, size }), className)}
          ref={ref}
        >
          {isReactText(children) ? <Text>{children}</Text> : children}
        </Link>
      </Provider>
    );
  }
);
