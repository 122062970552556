import { cva } from 'class-variance-authority';
import { type ForwardedRef, Fragment, forwardRef } from 'react';
import {
  type DialogProps as RACDialogProps,
  Button,
  composeRenderProps,
  Provider,
  Dialog as RACDialog,
} from 'react-aria-components';

import { CloseIcon } from '../../icons';
import { type LiteralVariantProps, type StyleProps, cn } from '../../utils';
import { ContentContext, FooterContext, HeadingContext } from '../content';
import { type ModalProps, Modal } from './Modal';
import { useDialogState } from './useDialogState';

// NOTE: definitive width rather than max-width: otherwise dialogs without
// sufficient content to fill the horizontal space will collapse, because of the
// centered flex layout of the overlay container.
const dialogVariants = cva('relative max-w-full', {
  variants: {
    size: {
      small: 'w-[28rem]',
      medium: 'w-[36rem]',
      large: 'w-[48rem]',
      fullscreen: 'w-screen h-full',
    },
  },
  defaultVariants: {
    size: 'medium',
  },
});

type DialogVariant = LiteralVariantProps<typeof dialogVariants>;
export type DialogProps = {
  /**
   * The size of the dialog.
   * @default 'medium'
   */
  size?: DialogVariant['size'];
} & Omit<RACDialogProps, 'className' | 'style' | 'role' | 'slot'> &
  Pick<ModalProps, 'isDismissable' | 'isKeyboardDismissDisabled'> &
  StyleProps;

/**
 * Dialogs are windows containing contextual information, tasks, or workflows
 * that appear over the user interface.
 */
export const Dialog = forwardRef(function Dialog(
  props: DialogProps,
  forwardedRef: ForwardedRef<HTMLDivElement>
) {
  const {
    className,
    isDismissable,
    isKeyboardDismissDisabled,
    size = 'medium',
    style,
    ...otherProps
  } = props;

  return (
    <Modal
      isDismissable={isDismissable}
      isKeyboardDismissDisabled={isKeyboardDismissDisabled}
      size={size}
    >
      <RACDialog
        className={cn(
          'flex flex-col outline-none',
          dialogVariants({ size }),
          className
        )}
        ref={forwardedRef}
        // @ts-expect-error Intentionally omitted from prop types; should
        // only be used by the `AlertDialog` component.
        role={props.role}
        style={{
          maxHeight:
            size === 'fullscreen'
              ? 'var(--visual-viewport-height)'
              : 'calc(var(--visual-viewport-height) - 2rem)',
          ...style,
        }}
        {...otherProps}
      >
        {composeRenderProps(props.children, (children) => (
          <Provider
            values={[
              [
                HeadingContext,
                {
                  className:
                    'title-2xl-semibold border-b border-subtle py-4 pe-14 ps-6',
                  slot: 'title',
                },
              ],
              [ContentContext, { className: 'flex-1 overflow-y-auto p-6' }],
              [
                FooterContext,
                { className: 'border-t border-subtle px-6 py-4' },
              ],
            ]}
          >
            <Fragment>
              {children}
              {isDismissable && <DismissButton />}
            </Fragment>
          </Provider>
        ))}
      </RACDialog>
    </Modal>
  );
});

function DismissButton() {
  const state = useDialogState();
  return (
    <Button
      onPress={state.close}
      aria-label="dismiss"
      slot="close"
      className={cn(
        `text-secondary absolute end-6 top-4 flex h-8 w-8 items-center justify-center rounded-sm outline-none`,
        `hover:bg-surfaceTwo hover:text-default`,
        `focus-visible:bg-surfaceTwo focus-visible:text-default focus-visible:ring-1 focus-visible:ring-offset-2`,
        `pressed:bg-surfaceOne`
      )}
    >
      <CloseIcon className="h-5 w-5 text-current" />
    </Button>
  );
}
