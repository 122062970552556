import { mergeIds, useObjectRef, useSlotId } from '@react-aria/utils';
import { forwardRef, useContext } from 'react';
import {
  type SwitchProps as RacSwitchProps,
  type SwitchRenderProps,
  composeRenderProps,
  DEFAULT_SLOT,
  Provider,
  Switch as RacSwitch,
} from 'react-aria-components';

import {
  type StyleProps,
  cn,
  isReactText,
  joinIds,
  useHasChild,
} from '../../utils';
import { CenterBaseline, Text, TextContext } from '../content';
import { SwitchGroupContext } from './SwitchGroup';

export type SwitchProps = {
  /**
   * The size of the switch and its label, if provided.
   * @default medium
   */
  size?: 'small' | 'medium';
} & Omit<RacSwitchProps, 'className' | 'style'> &
  StyleProps;

/**
 * Switches allow users to turn an individual option on or off. They are usually
 * used to activate or deactivate a specific setting.
 */
export const Switch = forwardRef<HTMLLabelElement, SwitchProps>(
  function Switch(props, forwardedRef) {
    const {
      className,
      isDisabled: isDisabledProp,
      size: sizeProp = 'medium',
      'aria-describedby': describedByProp,
      ...otherProps
    } = props;

    const groupContext = useContext(SwitchGroupContext);
    const size = groupContext?.size ?? sizeProp;
    const isDisabled = groupContext?.isDisabled ?? isDisabledProp;

    const labelRef = useObjectRef(forwardedRef);
    const hasDescriptionSlot = useHasChild('[slot=description]', labelRef);
    const descriptionSlotId = useSlotId([hasDescriptionSlot]);

    return (
      <RacSwitch
        ref={labelRef}
        aria-describedby={joinIds([describedByProp, descriptionSlotId])}
        isDisabled={isDisabled}
        className={cn(
          'group',
          {
            'body-xs-medium': size === 'small',
            'body-sm-medium': size === 'medium',
            'inline-flex items-baseline gap-2': !groupContext,
            'flex flex-row-reverse items-center justify-between gap-3':
              groupContext,
          },
          className
        )}
        {...otherProps}
      >
        {composeRenderProps(props.children, (children, renderProps) => (
          <Provider
            values={[
              [
                TextContext,
                {
                  slots: {
                    // typically all typographic styles would be applied here,
                    // but we need the line-height to be declared on the wrapper
                    // for the baseline alignment
                    [DEFAULT_SLOT]: {
                      className: cn({
                        'text-disabled': renderProps.isDisabled,
                      }),
                    },
                    description: {
                      // hide from screen readers since it's inside the label
                      // element. applied by "aria-describedby" to the input
                      'aria-hidden': true,
                      id: descriptionSlotId,
                      className: cn('text-secondary font-normal', {
                        'text-disabled': renderProps.isDisabled,
                      }),
                    },
                  },
                },
              ],
            ]}
          >
            <>
              <CenterBaseline>
                <SwitchIndicator size={size} {...renderProps} />
              </CenterBaseline>

              {children &&
                (isReactText(children) ? (
                  <Text>{children}</Text>
                ) : (
                  <div className="grid">{children}</div>
                ))}
            </>
          </Provider>
        ))}
      </RacSwitch>
    );
  }
);

function SwitchIndicator(props: SwitchRenderProps & Pick<SwitchProps, 'size'>) {
  const { isDisabled, size } = props;

  return (
    <div
      className={cn(
        'relative rounded-full outline-none',
        'h-[var(--track-height)] w-[var(--track-width)]',
        'motion-safe:transition-colors motion-safe:ease-out',
        'bg-shark5',
        'group-hover:bg-shark6',
        'group-pressed:bg-shark5',
        'group-focus-visible:outline-accent',
        'group-disabled:bg-disabled',
        'group-selected:bg-accentEmphasis',
        'group-selected:group-hover:bg-accentEmphasisHover',
        'group-selected:group-pressed:bg-accentEmphasisActive',
        'group-selected:group-disabled:bg-disabled'
      )}
      style={{
        // @ts-expect-error — variables are valid CSS properties
        '--track-height': size === 'small' ? '16px' : '24px',
        '--track-gutter': '2px',
        '--track-width':
          'calc(var(--handle-size) * 2 + var(--track-gutter) * 2)',
        '--handle-size': 'calc(var(--track-height) - var(--track-gutter) * 2)',
      }}
    >
      <div
        className={cn(
          'text-default rounded-full will-change-transform motion-safe:transition-all motion-safe:ease-out',
          // hack to use foreground color as background color
          'bg-[currentColor]',
          'absolute m-[var(--track-gutter)] size-[var(--handle-size)]',
          'group-selected:translate-x-full',
          'group-disabled:text-disabled'
        )}
        style={{
          // TW shadows not dark enough for this case
          boxShadow: isDisabled ? undefined : '0 1px 3px 0 rgb(0 0 0 / 0.33)',
        }}
      />
    </div>
  );
}
