import { mergeProps, useObjectRef } from '@react-aria/utils';
import { cva } from 'class-variance-authority';
import { type ForwardedRef, type ReactNode, forwardRef } from 'react';
import { useButton, useFocusRing, useHover } from 'react-aria';
import { Provider } from 'react-aria-components';

import { ArrowOutwardIcon, IconContext } from '../../icons';
import { cn, isReactText, toDataAttributes } from '../../utils';
import { Text } from '../content';

import { Link } from './Link';
import type { CommonLinkProps, LinkDOMProps } from './types';

type TextLinkAnchorProps = {
  /** Indicate that the link will open in a new tab. */
  isExternal?: boolean;
} & LinkDOMProps &
  CommonTextLinkProps;

type CommonTextLinkProps = {
  /** The content of the element. */
  children: ReactNode;
  /**
   * The visual prominence of the link.
   * @default 'medium'
   */
  prominence?: 'high' | 'medium' | 'low';
} & CommonLinkProps;

type TextLinkButtonProps = CommonTextLinkProps;

export type TextLinkProps = TextLinkAnchorProps | TextLinkButtonProps;

/**
 * Text links take users to another place in the application, and usually appear
 * within or directly following a sentence. Styled to resemble a hyperlink.
 */
export const TextLink = forwardRef(function TextLink(
  props: TextLinkProps,
  ref: ForwardedRef<HTMLAnchorElement | HTMLSpanElement>
) {
  const className = cn(
    variants({ prominence: props.prominence }),
    props.className
  );

  if (isAnchorProps(props)) {
    return (
      <Link
        {...props}
        className={className}
        ref={ref as ForwardedRef<HTMLAnchorElement>}
        target={props.isExternal ? '_blank' : props.target}
      >
        <Provider values={[[IconContext, iconContext]]}>
          {wrapChildren(props.children)}
          {props.isExternal && (
            <ArrowOutwardIcon aria-label=", opens in new tab" />
          )}
        </Provider>
      </Link>
    );
  }

  return (
    <TextLinkButton
      {...props}
      ref={ref as ForwardedRef<HTMLSpanElement>}
      className={className}
    />
  );
});

// Would **much** prefer to use the `Button` component from
// "react-aria-components". All the shenanigans below are to fix a
// presentational limitation of the `<button/>` element, which refuses to
// display as `inline[-*]` no matter what CSS you throw at it.
const TextLinkButton = forwardRef(function TextLinkButton(
  props: TextLinkButtonProps,
  forwardedRef: ForwardedRef<HTMLSpanElement>
) {
  const { children, className, isDisabled, style } = props;

  const domRef = useObjectRef(forwardedRef);
  const { buttonProps, isPressed } = useButton(
    { elementType: 'span', ...props },
    domRef
  );
  const { focusProps, isFocused, isFocusVisible } = useFocusRing(props);
  const { hoverProps, isHovered } = useHover(props);
  const state = { isDisabled, isPressed, isHovered, isFocused, isFocusVisible };

  return (
    <span
      {...mergeProps(buttonProps, focusProps, hoverProps)}
      {...toDataAttributes(state, { includeRacIdentifier: true })}
      ref={domRef}
      className={className}
      style={style}
    >
      <Provider values={[[IconContext, iconContext]]}>
        {wrapChildren(children)}
      </Provider>
    </span>
  );
});

// Utils
// -----------------------------------------------------------------------------

function isAnchorProps(props: TextLinkProps): props is TextLinkAnchorProps {
  return 'href' in props && props.href != null;
}

// Text nodes must be wrapped with an element to get the first/last child
// pseudo-elements to work around icons. Using `Text` for consistency with
// other content components but it isn't being targetted for styling (yet),
// just renders a simple span.
function wrapChildren(children: ReactNode) {
  return isReactText(children) ? <Text>{children}</Text> : children;
}

// Context
// -----------------------------------------------------------------------------

const iconContext = {
  // match the size of the text, but don't let it shrink smaller than
  // 1rem/16px to avoid weird SVG rendering artifacts.
  className: 'inline size-[1em] min-h-4 min-w-4',
  // use the word joiner**, a zero-width non-breaking character, to
  // prevent the icon from wrapping onto its own line. the CSS pseudo-elements
  // approach is to avoid mapping over children + interrogating the
  // `node.type.displayName` or some other silliness.
  //
  // ** prettier replaces the unicode character `\u2060` with the literal
  // character but trust me, it's there.
  render: (icon: ReactNode) => (
    <span
      className={cn(
        'relative inline text-nowrap',
        'last:ms-0.5 last:before:content-["⁠"]',
        'first:me-0.5 first:after:content-["⁠"]'
      )}
      style={{ top: '-0.05em' }}
    >
      {icon}
    </span>
  ),
};

// Variants
// -----------------------------------------------------------------------------

const variants = cva(
  `
  break-word outline-none transition-all cursor-pointer min-w-0 underline underline-offset-2 decoration-solid [text-decoration-thickness:1px]
  aria-disabled:no-underline aria-disabled:cursor-auto
  `,
  {
    variants: {
      prominence: {
        high: `
        hover:decoration-transparent
        focus-visible:decoration-double
        `,
        medium: `
        decoration-[color-mix(in_srgb,currentColor_40%,transparent)]
        hover:decoration-[currentColor]
        focus-visible:decoration-[currentColor] focus-visible:decoration-double
      `,
        low: `
        decoration-transparent	
        hover:brightness-150
        focus-visible:brightness-150 focus-visible:decoration-[currentColor] focus-visible:decoration-double
      `,
      },
    },
    defaultVariants: {
      prominence: 'medium',
    },
  }
);
