import type { AriaLabelingProps, DOMProps } from '@react-types/shared';
import { type ForwardedRef, forwardRef, ReactNode } from 'react';

import { HelpIcon, InfoIcon } from '../../icons';
import { type StyleProps, cn } from '../../utils';

import { Button } from '../button';
import { type DialogTriggerProps } from '../dialog';
import { type PopoverProps, Popover, PopoverTrigger } from '../popover';

export type ContextualHelpProps = {
  /** The content of the popover. */
  children: ReactNode;
  /**
   * Indicates whether popover content is informative or provides helpful guidance.
   *
   * @default 'help'
   */
  variant?: 'info' | 'help';
} & Pick<DialogTriggerProps, 'defaultOpen' | 'isOpen' | 'onOpenChange'> &
  Pick<PopoverProps, 'placement'> &
  DOMProps &
  AriaLabelingProps &
  StyleProps;

/**
 * Contextual help shows a user extra information about the state or behaviour
 * of either an adjacent component or the related view.
 */
export const ContextualHelp = forwardRef(function ContextualHelp(
  props: ContextualHelpProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  const {
    children,
    defaultOpen,
    isOpen,
    onOpenChange,
    placement = 'bottom start',
    variant,
    ...buttonProps
  } = props;
  const Icon = variant === 'info' ? InfoIcon : HelpIcon;
  const label = variant === 'info' ? 'Information' : 'Help';

  return (
    <PopoverTrigger
      defaultOpen={defaultOpen}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
    >
      <Button
        ref={ref}
        aria-label={label}
        prominence="none"
        className={cn('size-5 min-h-0 min-w-0', buttonProps.className)}
        {...buttonProps}
      >
        <Icon className="size-4" />
      </Button>
      <Popover className="md:min-w-96" placement={placement}>
        {children}
      </Popover>
    </PopoverTrigger>
  );
});
