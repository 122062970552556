/**
 * @TODO: move this inside `NumberTicker` if it's only used for that
 * @example
 * addDollarSuffix(1234) // '$1.23K'
 * addDollarSuffix(1_234_567) // '$1.23M'
 * addDollarSuffix(1_234_567_890) // '$1.23B'
 * addDollarSuffix(12_234_567_890_123) // '$12.23T'
 */
export function addDollarSuffix(num: number) {
  if (num >= 1_000_000_000_000) {
    return `$${(num / 1_000_000_000_000).toFixed(2)}T`;
  } else if (num >= 1_000_000_000) {
    return `$${(num / 1_000_000_000).toFixed(2)}B`;
  } else if (num >= 1_000_000) {
    return `$${(num / 1_000_000).toFixed(2)}M`;
  } else if (num >= 1_000) {
    return `$${(num / 1_000).toFixed(2)}K`;
  } else {
    return `$${num}`;
  }
}

export const formatDate = (isoDate: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    timeZone: 'UTC',
  };
  return new Date(isoDate).toLocaleDateString('en-US', options);
};

export const formatNumber = (value: number) => {
  return value.toLocaleString('en-US', {
    useGrouping: true,
    maximumFractionDigits: 2,
  });
};

/**
 * Truncate an address, preserving `n` characters from the start and end.
 * Hexadecimal addresses maintain their `0x` prefix.
 *
 * @param value - The address to truncate.
 * @param preserve - The number of characters to preserve from the start and end (default `4`).
 */
export function truncateAddress(value: string, preserve: number = 4): string {
  if (value.startsWith('0x')) {
    const start = value.slice(2, preserve + 2);
    const end = value.slice(-preserve);

    return `0x${start}…${end}`;
  }

  const start = value.slice(0, preserve);
  const end = value.slice(-preserve);

  return `${start}…${end}`;
}
