import { useObjectRef, useSlotId } from '@react-aria/utils';
import { type SVGProps, forwardRef, useContext } from 'react';
import {
  type CheckboxProps as RACCheckboxProps,
  type CheckboxRenderProps,
  composeRenderProps,
  DEFAULT_SLOT,
  Provider,
  Checkbox as RACCheckbox,
  TextContext as RACTextContext,
} from 'react-aria-components';

import {
  type StyleProps,
  cn,
  isReactText,
  joinIds,
  useHasChild,
  usePrevious,
} from '../../utils';
import { CenterBaseline, Text, TextContext } from '../content';
import { FieldContext } from '../field';

export type CheckboxProps = {
  /**
   * The size of the checkbox.
   * @default medium
   */
  size?: 'small' | 'medium' | 'large';
} & Omit<
  RACCheckboxProps,
  'className' | 'style' | 'isInvalid' | 'validationBehavior'
> &
  StyleProps;

/**
 * A checkbox allows a user to select multiple options from a list of items, or
 * to toggle a boolean value when used as a discrete form control.
 */
export const Checkbox = forwardRef<HTMLLabelElement, CheckboxProps>(
  function Checkbox(props, forwardedRef) {
    const {
      'aria-describedby': describedByProp,
      className,
      ...otherProps
    } = props;

    const ctx = useContext(FieldContext);
    const size = props.size ?? ctx.size;

    const labelRef = useObjectRef(forwardedRef);
    const hasDescriptionSlot = useHasChild('[slot=description]', labelRef);
    const descriptionSlotId = useSlotId([hasDescriptionSlot]);

    return (
      <RACCheckbox
        aria-describedby={joinIds([describedByProp, descriptionSlotId])}
        className={cn(
          'group',
          'flex items-baseline gap-2',
          {
            'body-xs-medium': size === 'small',
            'body-sm-medium': size === 'medium',
            'body-base-medium': size === 'large',
          },
          className
        )}
        ref={labelRef}
        {...otherProps}
      >
        {composeRenderProps(props.children, (children, renderProps) => (
          <Provider
            values={[
              // clear "description" and "errorMessage" slots
              [RACTextContext, {}],
              [
                TextContext,
                {
                  slots: {
                    // typically all typographic styles would be applied here,
                    // but we need the line-height to be declared on the wrapper
                    // for the baseline alignment
                    [DEFAULT_SLOT]: {
                      className: cn({
                        'text-disabled': renderProps.isDisabled,
                      }),
                    },
                    description: {
                      // hide from screen readers since it's inside the label
                      // element. applied by "aria-describedby" to the input
                      'aria-hidden': true,
                      id: descriptionSlotId,
                      className: cn('text-secondary font-normal', {
                        'text-disabled': renderProps.isDisabled,
                      }),
                    },
                  },
                },
              ],
            ]}
          >
            <CenterBaseline>
              <CheckboxIndicator size={size} {...renderProps} />
            </CenterBaseline>
            {isReactText(children) ? (
              <Text>{children}</Text>
            ) : (
              <div className="grid">{children}</div>
            )}
          </Provider>
        ))}
      </RACCheckbox>
    );
  }
);

const INDICATOR_SIZE = {
  small: '16px',
  medium: '18px',
  large: '20px',
};

function CheckboxIndicator(
  props: CheckboxRenderProps & { size: CheckboxProps['size'] }
) {
  const { isSelected, isIndeterminate } = props;

  // Keep track of state to avoid a brief flash of the checkmark icon when
  // transitioning from indeterminate to unselected.
  const prevIndeterminate = usePrevious(isIndeterminate);
  const Icon =
    isIndeterminate || (prevIndeterminate && !isSelected)
      ? DashSvg
      : CheckmarkSvg;

  return (
    <div
      className={cn(
        'bg-neutral border-interactive size-[var(--size)] rounded-[0.25rem] border-2 text-transparent outline-none',
        'flex items-center justify-center',
        'motion-safe:transition-all motion-safe:ease-out',
        'group-hover:bg-neutralHover',
        'group-pressed:bg-neutralActive',
        'group-focus-visible:outline-accent',
        // selected
        'group-selected:border-interactiveActive group-selected:text-shark0 group-selected:border-[calc(var(--size)*0.5)]',
        'group-selected:group-hover:border-interactiveHoverActive',
        'group-selected:group-pressed:border-interactiveActive',
        // indeterminate
        'group-indeterminate:border-interactiveActive group-indeterminate:text-shark0 group-indeterminate:border-[calc(var(--size)*0.5)]',
        'group-indeterminate:group-hover:border-interactiveHoverActive',
        'group-indeterminate:group-pressed:border-interactiveActive',
        // disabled
        'group-disabled:bg-disabled group-disabled:border-transparent',
        'group-selected:group-disabled:border-secondary',
        'group-indeterminate:group-disabled:border-secondary',
        // readonly
        'group-data-[readonly]:border group-data-[readonly]:outline-1',
        'group-selected:group-data-[readonly]:border-secondary',
        'group-indeterminate:group-data-[readonly]:border-secondary',
        // invalid
        'group-invalid:border-interactiveCritical'
      )}
      style={{
        // @ts-expect-error — CSS vars are valid CSS properties
        '--size': INDICATOR_SIZE[props.size],
      }}
    >
      {Icon && (
        <Icon
          className={cn(
            'size-[calc(var(--size)-4px)] shrink-0',
            'motion-safe:transition-all motion-safe:ease-out'
          )}
          style={{
            transform: `scale(${isIndeterminate || isSelected ? 1 : 0.5})`,
          }}
        />
      )}
    </div>
  );
}

/**
 * Simple checkmark SVG. The component lib’s `CheckIcon` doesn't convey enough
 * “weight”, especially at small sizes, for this occassion.
 */
function CheckmarkSvg(props: SVGProps<SVGSVGElement>) {
  const { className, ...otherProps } = props;
  return (
    <svg
      aria-hidden="true"
      className={cn('stroke-current stroke-[3]', className)}
      fill="none"
      focusable="false"
      role="img"
      viewBox="0 0 16 16"
      {...otherProps}
    >
      <polyline points="2 9, 6 13, 14 3" />
    </svg>
  );
}

/** Simple dash/hyphen SVG to match the above checkmark, for consistency. */
function DashSvg(props: SVGProps<SVGSVGElement>) {
  const { className, ...otherProps } = props;
  return (
    <svg
      aria-hidden="true"
      className={cn('stroke-current stroke-[3]', className)}
      fill="none"
      focusable="false"
      role="img"
      viewBox="0 0 16 16"
      {...otherProps}
    >
      <polyline points="2 8, 14 8" />
    </svg>
  );
}
