import { createIcon } from '../createIcon';

export const QrCodeIcon = createIcon(
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 5.5a2 2 0 0 1 2-2H9a2 2 0 0 1 2 2V9a2 2 0 0 1-2 2H5.5a2 2 0 0 1-2-2V5.5Zm2-.5H9a.5.5 0 0 1 .5.5V9a.5.5 0 0 1-.5.5H5.5A.5.5 0 0 1 5 9V5.5a.5.5 0 0 1 .5-.5ZM3.5 15a2 2 0 0 1 2-2H9a2 2 0 0 1 2 2v3.5a2 2 0 0 1-2 2H5.5a2 2 0 0 1-2-2V15Zm2-.5H9a.5.5 0 0 1 .5.5v3.5a.5.5 0 0 1-.5.5H5.5a.5.5 0 0 1-.5-.5V15a.5.5 0 0 1 .5-.5ZM15 3.5a2 2 0 0 0-2 2V9a2 2 0 0 0 2 2h3.5a2 2 0 0 0 2-2V5.5a2 2 0 0 0-2-2H15ZM18.5 5H15a.5.5 0 0 0-.5.5V9a.5.5 0 0 0 .5.5h3.5A.5.5 0 0 0 19 9V5.5a.5.5 0 0 0-.5-.5Z"
    />
    <path d="M14.25 13a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5ZM13 19.25a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0ZM19.25 13a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z" />
  </>,
  'QrCodeIcon'
);
