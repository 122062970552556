import { Link, Text } from '@infinex/ui/components';
import { useFeatureFlagPayload } from 'posthog-js/react';
import PatronNftMarketplaceLogos from '@/assets/images/patron-nft-marketplaces-logos.png';

type MarketplaceLink = {
  name: string;
  url: string;
};

type MarketPlaceData = {
  marketplace_links: MarketplaceLink[];
  floor_price: number | undefined;
};

const defaultMarketplaceData: MarketPlaceData = {
  marketplace_links: [
    { name: 'Blur', url: 'https://blur.io' },
    { name: 'Magic Eden', url: 'https://magiceden.io' },
    { name: 'OpenSea', url: 'https://opensea.io' },
  ],
  floor_price: undefined,
};

export const EmptyState = () => {
  const strokeWidth = 2;
  const viewBoxSize = 48;
  const center = viewBoxSize / 2;
  const r = center - strokeWidth - 2; // Subtract 2 to account for the glow effect
  const c = 2 * r * Math.PI;

  const marketplaceLinks = (useFeatureFlagPayload('patron-marketplace-links') ??
    defaultMarketplaceData) as MarketPlaceData;

  return (
    <div className="bg-shark2 z-10 mb-10 flex min-h-[442px] w-full flex-col items-center justify-between gap-y-5 rounded-lg p-10  md:flex-row">
      <div className="relative flex w-1/2 flex-col items-center justify-center">
        <svg
          width="270"
          height="270"
          viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
          fill="none"
          strokeWidth={strokeWidth}
        >
          <circle
            cx={center}
            cy={center}
            r={r}
            stroke="#272A2F"
            strokeLinecap="round"
          />
        </svg>
        <div className="absolute inset-0 flex flex-col items-center justify-center gap-4">
          <span className="title-5xl-semibold text-subtle leading-none">0</span>
          <span className="body-sm-normal text-subtle max-w-30 text-center ">
            You don’t have any Patrons
          </span>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center gap-6 md:w-1/2 ">
        <img
          src={PatronNftMarketplaceLogos}
          alt="Patron NFT Marketplaces"
          width="90px"
          height="36px"
        />
        <Text className="text-slate11 px-4 text-center ">
          The Infinex Patrons Collection can be found on{' '}
          {marketplaceLinks.marketplace_links.map((link, index) => (
            <>
              <Link
                key={link.name}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                className=" text-default decoration-subtle underline hover:no-underline"
              >
                {link.name}
              </Link>
              {index < marketplaceLinks.marketplace_links.length - 1 && ', '}
            </>
          ))}
        </Text>
      </div>
    </div>
  );
};
