import { useObjectRef, useSlotId } from '@react-aria/utils';
import { forwardRef, useContext } from 'react';
import {
  type RadioProps as RACRadioProps,
  type RadioRenderProps,
  composeRenderProps,
  DEFAULT_SLOT,
  Provider,
  Radio as RACRadio,
  TextContext as RACTextContext,
} from 'react-aria-components';

import {
  type StyleProps,
  cn,
  isReactText,
  joinIds,
  toDataAttributes,
  useHasChild,
} from '../../utils';
import { CenterBaseline, Text, TextContext } from '../content';
import { FieldContext } from '../field';

export type RadioProps = Omit<RACRadioProps, 'className' | 'style'> &
  StyleProps;

/**
 * Only valid inside a `RadioGroup`, a radio button represents a single option
 * within a list of mutually exclusive options.
 */
export const Radio = forwardRef<HTMLLabelElement, RadioProps>(
  function Radio(props, forwardedRef) {
    const {
      'aria-describedby': describedByProp,
      className,
      ...otherProps
    } = props;

    const { size } = useContext(FieldContext);

    const labelRef = useObjectRef(forwardedRef);
    const hasDescriptionSlot = useHasChild('[slot=description]', labelRef);
    const descriptionSlotId = useSlotId([hasDescriptionSlot]);

    return (
      <RACRadio
        aria-describedby={joinIds([describedByProp, descriptionSlotId])}
        className={cn(
          'flex items-baseline gap-2',
          {
            'body-xs-medium': size === 'small',
            'body-sm-medium': size === 'medium',
            'body-base-medium': size === 'large',
          },
          className
        )}
        ref={labelRef}
        {...otherProps}
      >
        {composeRenderProps(props.children, (children, renderProps) => (
          <Provider
            values={[
              // clear "description" and "errorMessage" slots
              [RACTextContext, {}],
              [
                TextContext,
                {
                  slots: {
                    // typically all typographic styles would be applied here,
                    // but we need the line-height to be declared on the wrapper
                    // for the baseline alignment
                    [DEFAULT_SLOT]: {
                      className: cn({
                        'text-disabled': renderProps.isDisabled,
                      }),
                    },
                    description: {
                      // hide from screen readers since it's inside the label
                      // element. applied by "aria-describedby" to the input
                      'aria-hidden': true,
                      id: descriptionSlotId,
                      className: cn('text-secondary font-normal', {
                        'text-disabled': renderProps.isDisabled,
                      }),
                    },
                  },
                },
              ],
            ]}
          >
            <CenterBaseline>
              <RadioIndicator {...renderProps} />
            </CenterBaseline>
            {isReactText(children) ? (
              <Text>{children}</Text>
            ) : (
              <div className="grid">{children}</div>
            )}
          </Provider>
        ))}
      </RACRadio>
    );
  }
);

function RadioIndicator(props: RadioRenderProps) {
  const { size } = useContext(FieldContext);

  return (
    <div
      {...toDataAttributes(props, { includeRacIdentifier: true })}
      className={cn(
        'bg-neutral border-interactive rounded-full border-2 outline-none transition-all ease-out',
        'hover:bg-neutralHover',
        'focus-visible:outline-accent',
        'selected:border-interactiveActive selected:bg-canvas selected:border-[0.33em]',
        'data-[read-only]:outline-1',
        'selected:data-[read-only]:border-secondary',
        'disabled:bg-disabled disabled:border-transparent',
        'selected:disabled:border-secondary',
        {
          'size-4': size === 'small' || size === 'medium',
          'size-5': size === 'large',
        }
      )}
    />
  );
}
