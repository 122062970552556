import { type ForwardedRef, forwardRef } from 'react';
import {
  type TooltipProps as RACTooltipProps,
  type TooltipRenderProps,
  type TooltipTriggerComponentProps as RACTooltipTriggerProps,
  OverlayArrow,
  TooltipTrigger as RACTooltipTrigger,
  Tooltip as RACTooltip,
  composeRenderProps,
} from 'react-aria-components';

import { type StyleProps, cn } from '../../utils';

export type TooltipProps = {
  /** The content to display in the tooltip. */
  children: React.ReactNode;
} & Pick<RACTooltipProps, 'placement'> &
  StyleProps;

/**
 * Tooltips show contextual help or information, on hover and focus, that
 * concisely describes the function of their associated element.
 */
export const Tooltip = forwardRef(function Tooltip(
  props: TooltipProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const { children, className, ...otherProps } = props;

  return (
    <RACTooltip
      {...otherProps}
      ref={ref}
      className={cn(
        'bg-surfaceTwo max-w-48 rounded px-3 py-1.5 shadow-sm',
        'body-xs-normal text-pretty',
        // common animations
        'entering:animate-in entering:fade-in entering:ease-out',
        'exiting:animate-out exiting:fade-out',
        // placement animations
        'data-[placement=bottom]:entering:slide-in-from-top-2 data-[placement=bottom]:mt-2',
        'data-[placement=top]:entering:slide-in-from-bottom-2 data-[placement=top]:mb-2',
        'data-[placement=left]:entering:slide-in-from-right-2 data-[placement=left]:mr-2',
        'data-[placement=right]:entering:slide-in-from-left-2 data-[placement=right]:ml-2',
        className
      )}
    >
      {composeRenderProps(children, (content, renderProps) => (
        <>
          <TooltipArrow {...renderProps} />
          {content}
        </>
      ))}
    </RACTooltip>
  );
});

// Arrow
// -----------------------------------------------------------------------------

function TooltipArrow(props: TooltipRenderProps) {
  const { placement } = props;

  return (
    <OverlayArrow>
      <svg
        width={10}
        height={10}
        viewBox="0 0 10 10"
        className={cn('fill-surfaceTwo', {
          'rotate-90': placement === 'right',
          'rotate-180': placement === 'bottom',
          '-rotate-90': placement === 'left',
        })}
      >
        <path d="M0 0 L5 5 L10 0" />
      </svg>
    </OverlayArrow>
  );
}

// Trigger
// -----------------------------------------------------------------------------

export type TooltipTriggerProps = Omit<
  RACTooltipTriggerProps,
  'closeDelay' | 'delay' | 'trigger'
>;

// Needs to be enough time to ensure intent, without making the user wait too
// long. Informed by: https://www.nngroup.com/articles/timing-exposing-content
const MOUSE_OPEN_DELAY = 600;
const MOUSE_CLOSE_DELAY = 500;

/**
 * A controller for the `Tooltip`, bound to an associated trigger element; it
 * handles opening and closing when the user hovers over or focuses the trigger.
 */
export function TooltipTrigger(props: TooltipTriggerProps) {
  return (
    <RACTooltipTrigger
      {...props}
      delay={MOUSE_OPEN_DELAY}
      closeDelay={MOUSE_CLOSE_DELAY}
    />
  );
}
